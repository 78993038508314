<template>
    <header>
      <nav>
        <div class="app-t-logo-container">
          <NuxtLink to="/">
            <img class="main-logo" :src="MainLogo2" alt="logo" />
          </NuxtLink>
          <span class="app-t-logo-text">Photo Tester</span>
        </div>
  
        <div class="app-t-nav-links">
          <ul>
            <li><NuxtLink to="/tests/TestDashboard">Dashboard</NuxtLink></li>
            <li><a href="/tests/addtestsections" > Test Your Photo </a></li>
            <li><a href="/tests/vote" > Vote </a></li>
            <li><a href="/pay3"> Get Credits </a></li>
            <li><a  href="/pay3"> Credits: {{ credits }}</a></li>
            <li><a  href="/pay3"> Energy: {{ energyDisplay }}</a></li>
            <li><a href="/blog">Blog</a></li>
             <!-- Add app for android -->
            <!-- <li class="app-t-top-menu-item">
                    <Icon name="ic:sharp-install-mobile" size="17"/> App
                </li> -->
            <li  v-if="!user"> <a href=""><NuxtLink to="/auth" class="app-t-login-button"> Login / Register</NuxtLink></a></li>
                <li i v-if="user">  <a href> <Icon name="ph:user-thin" size="17"/>  {{  user.email }}</a>  </li> 
                <li v-if="user" @click="client.auth.signOut()" ><a href> Sign out</a></li>
                </ul>
              </div>
           
             
       

        <div class="app-t-mobile-nav" >
          <i id="menu_show" class="ri-menu-2-line" @click="showMobileMenu"> <Icon name="mdi:menu"  class="ml-5"/></i>
          <i id="menu_hide" class="ri-close-line" @click="hideMobileMenu" style="display: none;"> <Icon name="mdi:close" class="ml-5"/></i>
        </div>
      </nav>
    </header>
  
    <div class="app-t-mobile-menu-container" style="display: none;">
      <div class="app-t-mobile-nav-links">
        <ul>
          <li><NuxtLink  to="/tests/TestDashboard" @click="hideMobileMenu">Dashboard</NuxtLink></li>
            <li><a   href="/tests/addtestsections" @click="hideMobileMenu">Test Your Photo </a></li>
            <li><a href="/tests/vote" @click="hideMobileMenu"> Vote </a></li>
            <li><a href="/pay3" @click="hideMobileMenu"> Add Funds </a></li>
            <li><a  href="/pay3" @click="hideMobileMenu"> Credits: {{ credits }}</a></li>
            <li><a  href="/pay3" @click="hideMobileMenu"> Energy: {{ energyDisplay }}</a></li>
            <li><a href="/blog" @click="hideMobileMenu">Blog</a></li>
            <!-- <li><a>Contact Us</a></li> -->
            <li  v-if="!user"> <a href="" @click="hideMobileMenu"><NuxtLink to="/auth" class="app-t-login-button"> Login / Register</NuxtLink></a></li>
                <li i v-if="user">  <a href="" @click="hideMobileMenu"> <Icon name="ph:user-thin" size="17"/>  {{  user.email }}</a>  </li> 
                <li v-if="user" ><a href="/auth" @click="client.auth.signOut()"> Sign out</a></li>
        
           <!-- Add app for android -->
           <!-- <li class="app-t-top-menu-item">
                    <Icon name="ic:sharp-install-mobile" size="17"/> App
                </li>  -->
        </ul>
      </div>
    </div>
  </template>
  
  <script  setup>
    import { MainLogo2 } from '~/models/types';
    import { useFetch } from '@vueuse/core';
    import { ref, onMounted } from 'vue';
    const client = useSupabaseClient()
    const user = useSupabaseUser()
    let isAccountMenu = ref(false)
    const userProfile = ref(null);
    const credits = ref(0)
    const energy = ref(0);
   


    const energyDisplay = computed(() => {
      
      // if(energy.value >= 25){
      //   return "High"
      // }else if(energy.value > 15){
      //   return "Medium";
      // }else if(energy.value > 0){
      //   return "Low"
      // }
      // return "None";
      return energy.value;
  });


    const fetchUserProfile = async () => {
      try {
        const response = await useFetch('/api/pranker/getUser').json();
        userProfile.value = response.data.value;
        if(userProfile.value && userProfile.value.data != null && userProfile.value.data[0].credits){
          credits.value = userProfile.value.data[0].credits;
          energy.value = userProfile.value.data[0].energy;
        }
  
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };




// Watch for changes in the user object and call fetchTests and fetchUserProfile when user is available
  watch(user, (newUser) => {
    if (newUser && newUser.id) {
      fetchUserProfile();
    }
  }, { immediate: true });


    //   onMounted(() => {
    //     fetchUserProfile();
    // });

  </script>

  <script>
    /* manage mobile menu */
   
      const navigateToSection = (sectionId) => {
        this.$router.push('/').then(() => {
          const element = document.querySelector(`#${sectionId}`);
          if (element) {
            const yOffset = -95;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        });
      };
      const showMobileMenu= () => {
        console.log("show-mobile-menu")
        document.querySelector('.app-t-mobile-menu-container').style.display = 'flex';
        document.getElementById('menu_show').style.display = 'none';
        document.getElementById('menu_hide').style.display = 'block';
      };

      const hideMobileMenu = () => {
        console.log("hide mobile menu");
        document.querySelector('.app-t-mobile-menu-container').style.display = 'none';
        document.getElementById('menu_hide').style.display = 'none';
        document.getElementById('menu_show').style.display = 'block';
      };
  
</script>
  
  <style   lang="scss"  scoped>

    a{
      color: white;
    }
  .ml-5{
    font-size: 35px;
    color: $header-primary-hover-color;
  }

  .app-t-nav-links{
    margin-right: 10px;
    font-size: 15px;
    font-size: bold;
    flex-wrap: wrap;
  }
  .app-t-logo-container {
    display: flex;
    align-items: center;
  }
  
  .app-t-logo-text {
    font-size: 18px;
    font-weight: 500;
    color: white;
  }
  
  .app-t-nav-links ul {
    display: flex;
    list-style: none;
  }
  
  .app-t-nav-links ul li {
    white-space: nowrap;
    text-align: center;
    color: white !important;
  }
  
  .app-t-nav-links ul li a {
    text-decoration: none;
    padding: 8px 10px;
    border-radius: 13px;
  }
  
  .app-t-nav-links ul li a.active,
  .app-t-nav-links ul li:hover a {
    color: $header-primary-hover-color;
  }
  
  .app-t-cta-container {
    display: flex;
    align-items: center;
  }
  
  .app-t-btn-fullBg {
    background-color: $header-primary-hover-color ;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 24px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .app-t-btn-fullBg:hover {
    background-color: #5dc7f8;
  }
  
  .app-t-mobile-nav {
    display: none;
  }
  
  @media screen and (max-width: 962px) {
    .app-t-nav-links {
      display: none;
    }
    
    .app-t-mobile-nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
    }
    
    .app-t-mobile-menu-container {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: $header-primary-color;
      z-index: 100;
    }
    
    .app-t-mobile-nav-links{
      text-align: center;
      width: 100%;
    }

    .app-t-mobile-nav-links ul {
      list-style: none;
      padding: 130px 2%  30px 2%;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .app-t-mobile-nav-links ul li:hover{
      //background-color: rgb(37, 102, 177);
      background-color: #47B2E4;
    }

    .app-t-mobile-nav-links ul li{
      color: #fcf7f8;
      padding: 14px;
      width: 100%;
    }
    
    .app-t-mobile-nav-links ul li a {
      text-decoration: none;
      font-size: 18px;
      font-weight: 600;
    }
  }
  
  header {
    width: 100%;
    height: 85px;
    padding: 0 6%;
    // position: fixed;
    top: 0;
    left: 0;
    background-color: $header-primary-color;
    box-shadow: 0px 0px 10px #44444446;
    z-index: 1000;
    padding-right: 10px;
  }
  
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  header{
    font: 400 14px / 20px Roboto, Helvetica Neue, sans-serif;
  }



 

  </style>